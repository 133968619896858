.tableContainer {
  @apply hidden;

  @screen sm {
    @apply block rounded-l-xl ml-8 overflow-x-auto mb-10;
  }
}

.roundTopLeft {
  @apply rounded-tl-md;
}

.roundTopRight {
  @apply rounded-tr-md;
}

.roundBottomLeft {
  @apply rounded-bl-md;
}

.roundBottomRight {
  @apply rounded-br-md;
}

.table {
  @apply hidden;
  @screen sm {
    @apply block border-2 border-transparent mx-auto text-xl text-center text-stone-500 rounded-md;

    th {
      @apply bg-ocean-400 text-center text-3xl py-8 px-[62px] border-b border-stone-100/40;
      &:not(:last-child) {
        @apply border-r border-stone-100/40;
      }
    }

    td {
      @apply py-8 px-[62px] w-[316px] border-b border-stone-100/40 bg-white;
      &:not(:last-child) {
        @apply border-r border-stone-100/40;
      }
    }
  }

  p {
    @apply text-base leading-5 font-sans whitespace-pre-line text-center mx-auto w-[186px];
  }
}

.narrowText {
  @apply leading-5 font-sans whitespace-pre-line text-center mx-auto w-[148px];
}

.gradient {
  @apply hidden;

  @screen sm {
    @apply block absolute h-[488px] w-8 bottom-[70px] right-0 opacity-50;

    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(217, 217, 217, 0) 100%
    );
  }
}
