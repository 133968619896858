.tableContainer {
  @apply rounded-l-xl flex flex-col;
  @screen sm {
    @apply hidden;
  }
}

.roundTop {
  @apply rounded-t-md;
}

.roundBottom {
  @apply rounded-b-md;
}

.table {
  @apply block border-2 border-transparent mx-auto text-xl text-center text-stone-500 rounded-md rounded-t-md h-[30rem];
  @screen lg {
    @apply hidden;
  }

  th {
    @apply bg-ocean-400 text-center text-3xl py-8 px-8 border-b border-stone-100/40 h-24;
    &:not(:last-child) {
      @apply border-r border-stone-100/40;
    }
  }

  td {
    @apply py-8 px-[62px] w-[286px] border-b border-stone-100/40 bg-white;
    &:not(:last-child) {
      @apply border-r border-stone-100/40;
    }
  }

  p {
    @apply text-base leading-5 font-sans whitespace-pre-line text-center mx-auto;
  }
}

.narrowText {
  @apply w-[140px];
}

.regularText {
  @apply w-[186px];
}
