.container {
  @apply text-left mx-auto items-center px-5 text-white max-w-6xl gap-20 mt-6;
  @screen md {
    @apply flex justify-between px-6 mt-14 pb-48 max-w-7xl w-4/5 gap-0;
  }
}

.header {
  @apply whitespace-pre-wrap text-[42px] font-sans font-semibold leading-[46px];

  @screen md {
    @apply mt-6 text-6xl leading-[66px];
  }
}

.subHeader {
  @apply text-mint-200 text-lg font-semibold tracking-wide text-left pt-12;
  @screen md {
    @apply pt-0;
  }
}

.linkGroup {
  @apply flex items-center;
}

.linkButton {
  @apply flex py-2 px-4 text-sm font-semibold tracking-[2%];
  @screen md {
    @apply py-3 text-lg min-w-[13.5rem] tracking-[0];
  }
}

.gradient {
  @apply bg-gradient-to-r from-button-1 to-mint-200 hover:shadow-2xl;
}

.getStarted {
  @apply py-2 px-4 mt-8 rounded-3xl text-sm leading-5 font-semibold -mr-2;

  @screen md {
    @apply py-3 px-2 text-lg leading-none min-w-[13.5rem] justify-center mr-0;
  }
}

.arrow {
  @apply align-bottom;
}

.viewPricing {
  @apply flex justify-start items-center gap-x-1 text-white mt-8 px-6 hover:text-stone-50 hover:drop-shadow-2xl;
}

.list {
  @apply list-none pt-16;
  @screen md {
    @apply pt-0;
  }
}

.flexTop {
  @apply flex flex-col max-w-[21rem] justify-start items-start mx-auto;

  @screen md {
    @apply min-w-[20rem] max-w-xl justify-around mx-0 mr-24;
  }
}

.flexCol {
  @apply flex flex-col justify-around items-center mx-auto;

  @screen md {
    @apply max-w-[33%] w-[33%];
  }
}
