.container {
  @apply mx-auto relative top-24 mt-12 pb-24;
  @screen md {
    @apply mt-96 mx-auto;
  }
}

.steps {
  @apply flex flex-col justify-between pb-10 items-center text-lg font-semibold rounded-2xl max-w-7xl mx-auto;

  @screen md {
    @apply flex-row justify-around items-start;
  }
}

.flexRow {
  @apply hidden;

  @screen md {
    @apply flex max-w-6xl mx-auto justify-between;
  }
}

.stepHeader {
  @apply font-sans font-semibold text-ocean-700 text-4xl m-0 text-center w-96 h-10;
}

.header {
  @apply font-semibold text-3xl text-center leading-9 text-primary pb-8;
}

.gradient {
  @apply bg-gradient-to-r from-button-1 to-mint-200;
}

.linkToVideo {
  @apply flex justify-center items-center gap-x-1 text-white rounded-3xl py-2 text-center m-0 w-44 h-10 text-sm hover:text-white hover:shadow-2xl;
}

.rightPadding {
  @apply mr-[2%];
}

.line {
  @apply hidden;

  @screen md {
    @apply block mt-12 bg-ocean-500 opacity-30 h-0.5;
  }
}

.icon {
  @apply m-0 mr-1 text-xl;

  @screen md {
    @apply mr-0 text-lg;
  }
}

.imgWidth {
  @apply w-full;
}

.showMobile {
  @apply mx-auto mb-8;

  @screen md {
    @apply hidden;
  }
}
