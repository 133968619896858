.item {
  @apply flex align-text-bottom pt-3 relative pl-5 pb-4;

  p {
    @apply text-lg font-normal font-sans;
  }

  svg {
    @apply relative -left-[1.48rem] inset-1;
  }

  &:before {
    @apply absolute bg-white opacity-30 top-0 bottom-0 content-[""] left-1 w-px;
  }

  &:first-child:before {
    @apply top-6;
  }

  &:last-child:before {
    @apply h-6;
  }

  @screen md {
    @apply relative flex align-text-bottom pt-0;

    p {
      @apply text-lg font-normal font-sans w-[300px] relative left-2;
    }

    svg {
      @apply absolute -left-[.22rem] inset-1;
    }

    &:before {
      @apply absolute bg-white opacity-30 top-0 bottom-0 left-1 content-[""] w-px;
    }

    &:first-child:before {
      @apply top-4;
    }

    &:last-child:before {
      @apply h-2;
    }
  }
}

.step {
  @apply relative m-0 pb-4 pl-5 flex items-center;

  p {
    @apply w-44 font-semibold text-xl leading-6 py-6;
  }

  button {
    @apply rounded-full bg-ocean-400 w-12 h-12 p-0 relative right-[14.5%] hover:border-ocean-400;
  }

  &:before {
    @apply absolute bg-ocean-500 opacity-30 top-0 bottom-0 content-[""] left-[.66rem] w-[2px];
  }

  &:first-child:before {
    @apply top-10;
  }

  &:last-child:before {
    @apply h-3;
  }

  @screen md {
    @apply flex flex-col items-center gap-4 relative -top-10 p-0;

    p {
      @apply w-36 text-center flex flex-col leading-6 text-lg font-semibold;
    }

    button {
      @apply rounded-full bg-ocean-400 w-12 h-12 p-0 m-0 hover:bg-ocean-400 mx-auto right-0;
    }

    &:before {
      @apply relative bg-transparent opacity-0 top-0 bottom-0 left-0 w-0;
    }

    &:first-child:before {
      @apply top-0;
    }

    &:last-child:before {
      @apply h-0;
    }
  }
}

.title {
  @apply font-semibold text-base text-center;
  @screen sm {
    @apply font-semibold text-lg leading-6 pr-12;
  }
}

.detail {
  @apply font-medium text-sm leading-6 text-left w-60;
  @screen md {
    @apply text-base w-[25.5rem];
  }
}

.container {
  @apply flex flex-col items-start;

  @screen md {
    @apply flex-row;
  }
}

.rightPadding {
  @apply pr-20;
}

.svgSize {
  svg {
    @apply relative -left-[1.73rem] inset-1 text-2xl;
  }

  @screen md {
    svg {
      @apply absolute -left-[.22rem] inset-1 text-base;
    }
  }
}
