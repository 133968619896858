.bgGrey {
  @apply bg-stone-50;
}

.container {
  @apply pt-12 pb-14 mx-auto text-center;
  @screen lg {
    @apply pt-24 pb-14 mx-auto max-w-5xl;
  }
}

.header {
  @apply normal-case text-3xl mb-12 mx-6 font-sans font-semibold;
}

.tableImage {
  @apply inline-block;
}

.icon {
  @apply m-0 mr-1 text-base h-auto leading-10 font-extralight px-3;

  @screen md {
    @apply mr-0 text-lg;
  }
}

.doltImg {
  @apply h-5;

  @screen lg {
    @apply h-4;
  }
}

.hostedImg {
  @apply h-6;

  @screen lg {
    @apply h-5;
  }
}

.dolthublab {
  @apply flex justify-center items-center text-white text-base h-5;
}

.toRight {
  @apply relative left-[5%];
}

.toLeft {
  @apply relative right-[5%];
}

.arrow {
  @apply relative;
}

.link {
  @apply inline-flex gap-x-1 text-link-1 items-center hover:text-link-2;
}

.narrowText {
  @apply text-base leading-5 font-sans whitespace-pre-line text-center mx-auto w-[148px];

  @screen sm {
    @apply normal-case leading-5 font-sans whitespace-pre-line text-center mx-auto w-[148px];
  }
}
