.container {
  @apply bg-stone-50;
}

.content {
  @apply text-left mx-auto px-6 pt-4 font-sans max-w-3xl;

  @screen lg {
    @apply flex justify-between px-6 pt-28 pb-8 max-w-6xl;
  }
}

.left {
  @apply pt-11 max-w-lg mx-auto pb-4;

  @screen lg {
    @apply pb-0;
  }
}

.leftHeader {
  @apply text-4xl font-semibold;

  @screen lg {
    @apply w-[487px];
  }
}

.leftText {
  @apply text-stone-500 text-[17px] font-normal my-5 leading-6 max-w-xs;

  @screen lg {
    @apply text-base w-[450px];
  }
}

.link {
  @apply mt-3 flex justify-start items-center gap-x-1 w-44;
}

.linkBlue {
  @apply text-link-1 hover:text-link-2;
}

.arrow {
  @apply align-bottom;
}

.right {
  @apply flex justify-between pt-8 max-w-lg mx-auto;

  @screen lg {
    @apply justify-evenly w-[40%] max-w-xl;
  }
}

.col {
  @apply flex flex-col w-[8.5rem];

  @screen lg {
    @apply w-44;
  }
}

.colHeader {
  @apply my-2 font-semibold;
}

.colText {
  @apply relative bottom-4 text-stone-500 text-[15px] font-normal my-5 leading-6;

  @screen lg {
    @apply text-base;
  }
}

.iconColor {
  @apply text-mint-200 text-3xl;
}
